import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "happeo-ui-kit"
    }}>{`Happeo UI kit`}</h1>
    <p>{`Install a package you need `}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add @happeouikit/buttons
`}</code></pre>
    <p>{`Check package specific guides from the menu on left.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      